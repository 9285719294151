import { Modal } from "@mui/material";
import { useState } from "react";
import styled from "styled-components";
import { mobile } from "../utilities/responsive";

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f9f9f9;
  width: 40vw;
  max-width: 750px;
  height: calc(325px + 20vw);
  max-height: 700px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);

  ${mobile({ height: "70vh", width: "90vw" })}
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${mobile({ alignItems: "center" })}
`;

const FormWrapper = styled.div`
  padding: calc(25px + 1vw);
  justify-content: space-around;
`;

const Input = styled.input`
  width: 100%;
  border: 1px solid #ccc;
  background: #fff;
  margin: 5px 0;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: Arial, Helvetica, sans-serif;
`;

const TextArea = styled.textarea`
  height: 15vh;
  width: 100%;
  resize: none;
  border: 1px solid #ccc;
  background: #fff;
  margin: 5px 0;
  font-family: inherit;
`;

const Header = styled.h2`
  font-size: calc(16px + 1vw);
  font-weight: 300;

  ${mobile({ fontSize: "26px" })}
`;

const SubHeader = styled.h3`
  font-size: calc(8px + 0.5vw);
  font-weight: 300;
  ${mobile({ fontSize: "12px" })}
`;

const SubmitButton = styled.button`
  cursor: pointer;
  width: 25%;
  border: none;
  background: #244bd8;
  color: #fff;
  padding: 10px;
  font-size: 16px;
  margin: 10px 0;
  text-transform: uppercase;

  ${mobile({ width: "100%", padding: "12px" })}
`;

const ContactModal = ({ open, onClose }) => {
  const [success, setSuccess] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSuccess(true);
  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Container>
          {!success ? (
            <FormWrapper>
              <Form>
                <Header>Contact Support</Header>
                <SubHeader>
                  Contact us with any questions or concerns and we'll do our
                  best to help!
                </SubHeader>
                <Input placeholder="Name" />
                <Input type="email" placeholder="E-Mail" />
                <TextArea placeholder="Tell us about how we can help you!" />
                <SubmitButton type="submit" onClick={handleSubmit}>
                  Submit
                </SubmitButton>
              </Form>
            </FormWrapper>
          ) : (
            <Header>Thanks! We'll be in touch!</Header>
          )}
        </Container>
      </Modal>
    </>
  );
};

export default ContactModal;
