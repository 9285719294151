import styled from "styled-components";

import { mobile } from "../../../utilities/responsive";
import sparksTwo from "../../../assets/Sparks2.png";
import sadIcon from "../../../assets/sadIcon1.png";
import lightningIcon from "../../../assets/lightningIcon2.png";
import squareIcon from "../../../assets/squareIcon3.png";
import walletIcon from "../../../assets/walletIcon4.png";
import trophyIcon from "../../../assets/trophyIcon5.png";
import botIcon from "../../../assets/botIcon6.png";
import { headerFontFamily } from "../../../utilities/theme";


const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* background-color: #fdfcf2; */
  padding: 5% 0;

  ${mobile({padding: "10% 0"})};
`;

const Icon = styled.img`
  width: 50px;
  margin-right: 25px;

`;

const Header = styled.h2`
  font-size: 48px;
  font-family: ${headerFontFamily};
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;

  ${mobile({ fontSize: "32px" })}
`;

const Text = styled.span`
  font-size: 20px;
  font-weight: 400;
  font-family: "Comfortaa", cursive;
  text-align: left;
  line-height: 30px;

  ${mobile({ fontSize: "16px", lineHeight: "25px" })}
`;

const ListWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 50%;
  max-width: 750px;

  ${mobile({ width: "90%", })}
`;

const ListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin: 25px 0;

`;

const IconList = () => {
  return (
    <Container>
      <Icon style={{width: "auto", marginRight: "0"}} src={sparksTwo} alt="star icon" />
      <Header>Benefits of Automation</Header>
      <ListWrapper>
        <ListItem>
          <Icon src={sadIcon} alt="sad icon" />
          <Text>
          Over 90% of people who attempt to day trade fail! Utilizing automated trading can give you the tools needed to succeed.
          </Text>
        </ListItem>
        <ListItem>
          <Icon src={lightningIcon} alt="lightning icon" />
          <Text>
            Speed. Our bots instantly calculate hundreds of variables when making trading
            decisions. Immediate execution of trades both long and short.
          </Text>
        </ListItem>
        <ListItem>
          <Icon src={squareIcon} alt="squares icon" />
          <Text>Eliminate costly mistakes caused by human emotion and error.</Text>
        </ListItem>
        <ListItem>
          <Icon src={walletIcon} alt="wallet icon" />
          <Text>Risk and money management parameters built in to maximize profit and minimize loss.</Text>
        </ListItem>
        <ListItem>
          <Icon src={trophyIcon} alt="trophy icon" />
          <Text>Successful trades long and short. No more worrying whether the market goes up or down.</Text>
        </ListItem>
        <ListItem>
          <Icon src={botIcon} alt="bot icon" />
          <Text>Automated hands-free trading.</Text>
        </ListItem>
      </ListWrapper>
    </Container>
  );
};

export default IconList;
