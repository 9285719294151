import styled from "styled-components";
import { mobile } from "./utilities/responsive";


const Container = styled.div`
  display: flex;
`

const BannerImage = styled.img`
  width: 100%;
  ${mobile({ height: "210px", objectFit: "cover", objectPosition: "left" })}

`

const Banner = ({ banner }) => {
  return (
    <Container>
      <BannerImage src={banner} alt="banner" />
    </Container>
  )
}

export default Banner