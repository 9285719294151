import styled from "styled-components";
import Navbar from "../Navbar";

import background from "../assets/bgComingSoon.jpg";
import { mobile } from "../utilities/responsive";
import { fontFamily, headerFontFamily } from "../utilities/theme";

const Container = styled.div`
  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: black;
  height: 100vh;
  width: 100vw;
  /* visibility: visible; */
  opacity: 1;
  transition: opacity 2s linear;

  ${mobile({ backgroundPosition: "center" })}

  &.hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 2s, opacity 2s linear;
  }
`;

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Header = styled.h2`
  font-size: 42px;
  font-family: ${headerFontFamily};
  font-weight: 600;
  color: white;
  text-transform: uppercase;

  ${mobile({ textAlign: "center" })}
`;

const Desc = styled.span`
  font-family: ${fontFamily};
  color: white;
  width: 33%;
  text-align: center;
  font-size: 20px;
  line-height: 30px;
  font-weight: 800;

  ${mobile({ width: "80%", fontSize: "16px", lineHeight: "25px" })}
`;

const Button = styled.a`
  margin: 50px 0;
  color: black;
  background-color: white;
  padding: 20px;
  border-radius: 50px;
  font-size: 20px;
  cursor: pointer;
  text-decoration: none;

  ${mobile({ margin: "25px 0", padding: "15px", fontSize: "16px" })}
`;

const NavLink = styled.a`
  color: white;
  font-size: 18px;
  border-bottom: 1px solid white;
  cursor: pointer;
  text-decoration: none;
`;

const ComingSoon = () => {
  return (
    <>
      <Navbar />
      <Container>
        <Wrapper>
          <Header>Marketplace Coming Soon</Header>
          <Desc>
            Building a Web3 Marketplace takes time, and we're working hard to
            deliver something special. Stay tuned for updates and our full
            launch in the near future!
          </Desc>
          <Button href="mailto:news@archpublic.io">Contact Us</Button>
          <NavLink
            href="https://bid.nftglee.com/"
            rel="noopener noreferrer"
            target="_blank"
          >
            View our old Marketplace
          </NavLink>
        </Wrapper>
      </Container>
    </>
  );
};

export default ComingSoon;
