import About from "./components/About";
import Banner from "../../Banner";
import BuiltWith from "./components/BuiltWith";
import Footer from "../../Footer";
import Navbar from "../../Navbar";
import Slider from "./components/Slider";

import archBanner from "../../assets/banner.jpg";
import MarqueeNoScroll from "./components/MarqueeNoScroll";
import NewsLetter from "../../Newsletter";

function AboutPage() {
  return (
    <>
      <Navbar />
      <Banner banner={archBanner}/>
      <MarqueeNoScroll />
      <About />
      <Slider />

      <BuiltWith />
      <NewsLetter />
      <Footer />
    </>
  );
}

export default AboutPage;
