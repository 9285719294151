import Banner from "../../Banner";
import Navbar from "../../Navbar";

import botsBanner from "../../assets/botsBanner.jpg"
import Marquee from "./components/Marquee";
import AboutBots from "./components/AboutBots";
import IconList from "./components/IconList";
import GraphicList from "./components/GraphicList";
import Footer from "../../Footer";
import AboutBottom from "./components/AboutBottom";
import Qualifier from "./components/Qualifier";
import NewsLetter from "../../Newsletter";
import TraderProfile from "./components/TraderProfile";
import IconListFutures from "./components/IconListFutures";
import IconListArch from "./components/IconListArch";

const TradingBots = () => {
  return (
    <>
    <Navbar />
    <Banner banner={botsBanner} />
    <Marquee />
    <AboutBots />
    {/* <Qualifier /> */}
    <IconList />
    <IconListArch />
    <GraphicList />
    <IconListFutures />
    {/* <TraderProfile /> */}
    <AboutBottom />
    <NewsLetter />
    {/* <Footer /> */}
    </>
  )
}

export default TradingBots