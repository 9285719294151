import styled from "styled-components";

// import sparksTwo from "../../../assets/Sparks2.png";
// import sparksThree from "../../../assets/Sparks3.png";
import { mobile } from "../../../utilities/responsive";
// import { headerFontFamily } from "../../../utilities/theme";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* background-color: #fdfcf2; */
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 75%;

  ${mobile({ width: "90%" })}
`;

// const Bottom = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   width: 33%;

//   ${mobile({ width: "66%" })}
// `;

// const Header = styled.h2`
//   font-size: 24px;
//   font-family: ${headerFontFamily};
//   font-weight: 500;
//   text-transform: uppercase;
//   text-align: center;

//   animation-name: fadeInLeft;
//   animation-delay: 0.1s;
//   animation-duration: 0.5s;
//   animation-timing-function: ease;
//   animation-fill-mode: both;

//   ${mobile({ fontSize: "28px" })}
// `;

const Text = styled.span`
  margin: 3% 0;
  font-size: 14px;
  font-family: "Comfortaa", cursive;
  text-align: center;
  line-height: 25px;
  color: darkgray;

  ${mobile({ fontSize: "10px", lineHeight: "14px", margin: "5% 0" })}
`;

const AboutBottom = () => {
  return (
    <Container>
      <Top>
        <Text>
          Disclaimer: There is a very high degree of risk involved in trading. Past results
          are not indicative of future returns. Arch Public, Inc and all
          individuals affiliated with it assume no responsibilities for your
          trading and investment results. The indicators, columns, articles and
          all other features associated with Arch Public, Inc are for
          educational purposes only and should not be construed as investment
          advice. Your use of all indicators, strategies, columns, articles and
          all other features is entirely at your own risk and it is your sole
          responsibility to evaluate the accuracy, completeness and usefulness
          of the information. Please note, we are not and do not claim to be
          financial advisors and recommend consulting a professional advisor
          before trading your own money. See our {" "}
            <a
              style={{
                fontWeight: "600",
                color: "inherit",
                textDecoration: "underline",
              }}
              href="https://www.archpublic.io/terms"
              rel="noopener noreferrer"
              target="_blank"
            >
              Terms and Conditions
            </a>{" "} for more information.
        </Text>
      </Top>
      {/* <Bottom>
        <Icon src={sparksThree} alt="spark icon" />
        <Header>why are our bots packaged within nfts?</Header>
        <Text>
          NFTs function as proof of ownership and give you control over your
          bot. As you diversify and grow into larger capital bots, you can buy
          and sell on the secondary market.
        </Text>
      </Bottom> */}
    </Container>
  );
};

export default AboutBottom;
