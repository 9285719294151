import { Drawer } from "@mui/material";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { mobile } from "./utilities/responsive";
import { headerFontFamily } from "./utilities/theme";

const Divider = styled.div`
  position: relative;
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 40px;
  margin-top: 40px;
  &:before {
    position: absolute;
    content: "";
    width: 30px;
    height: 30px;
    border: 1px solid #f0f0f0;
    left: 50%;
    margin-left: -15px;
    top: 50%;
    background: #fff;
    margin-top: -15px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  &:after {
    position: absolute;
    content: "";
    width: 20px;
    height: 20px;
    border: 1px solid #ff94a4;
    left: 50%;
    margin-left: -10px;
    top: 50%;
    background: #ff94a4;
    margin-top: -10px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  margin: 0 20px;
  text-align: center;
  ${mobile({ width: "60vw" })}
`;

const MainTitle = styled.h3`
  font-size: 42px;
  font-family: ${headerFontFamily};
  margin: 20px 0;
  text-transform: uppercase;
`;

const Title = styled.a`
  font-size: 28px;
  font-family: ${headerFontFamily};
  margin: 20px 0;
  text-transform: uppercase;
  padding: 15px;
  cursor: pointer;
  text-align: right;
  text-decoration: none;
  color: inherit;
`;

const LinkTitle = styled(Link)`
  font-size: 28px;
  font-family: ${headerFontFamily};
  margin: 20px 0;
  text-transform: uppercase;
  padding: 15px;
  cursor: pointer;
  text-align: right;
  text-decoration: none;
  color: inherit;
`;

const MobileMenu = ({ anchor, displayBox, toggleDrawer }) => {
  return (
    <Drawer
      anchor={anchor}
      open={displayBox["right"]}
      onClose={toggleDrawer("right", false)}
    >
      <Container>
        <MainTitle>Arch Public</MainTitle>
        <Divider />
        {/* <DrawerItem>
          Arch Public is currently under construction, but while we work feel free to explore and learn a little about who we are and what we do!
        </DrawerItem> */}

        <LinkTitle to="/">Home</LinkTitle>
        <LinkTitle to="/bots">Trading Bots</LinkTitle>
        {/* <LinkTitle to="/">Marketplace (Coming Soon!)</LinkTitle> */}
        {/* <LinkTitle to="/support">Support</LinkTitle> */}
        <Title
          href="https://calendly.com/botsupport-kfm/30min"
          rel="noopener noreferrer"
          target="_blank"
        >
          Schedule a Demo
        </Title>
      </Container>
    </Drawer>
  );
};

export default MobileMenu;
