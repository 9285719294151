import styled from "styled-components";

import botImage from "../../../assets/archyP.jpg";
import { headerFontFamily } from "../../../utilities/theme";
import { mobile } from "../../../utilities/responsive";
import Question from "../../Support/components/Question";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10%;
  padding-bottom: 10%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 75%;
  align-self: center;

  ${mobile({ flexDirection: "column", width: "90%" })}
`;

const Left = styled.div`
  width: 50vw;
  display: flex;
  align-items: center;
  justify-content: center;
  ${mobile({ width: "90vw" })}
`;

const BotImage = styled.img`
  width: 500px;
  height: auto;

  ${mobile({ width: "90vw" })}
`;

const Right = styled.div`
  width: 50vw;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  ${mobile({ width: "90vw" })}
`;

const Header = styled.h3`
  font-size: 36px;
  width: 70%;
  font-weight: 400;
  font-family: ${headerFontFamily};
  text-transform: uppercase;

  ${mobile({
    textAlign: "center",
    width: "100%",
    marginTop: "50px",
    marginBottom: "15px",
    marginLeft: "-10px"
  })}
`;

const ListContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 75%;

  ${mobile({ textAlign: "left", width: "100%", marginLeft: "15px" })}
`;

const ListItem = styled.li`
  margin: 5px 0;
  justify-content: flex-start;
  font-size: 16px;
  line-height: 25px;
  font-family: "Comfortaa", cursive;
  list-style-position: outside;
  max-width: 400px;
`;

const Bottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  visibility: hidden;
`;

const GraphicList = () => {
  return (
    <Container>
      <Wrapper>
        <Left>
          <BotImage src={botImage} alt="bot image" />
        </Left>
        <Right>
          <ListContainer>
            <Header>Pivot Bot - Key Features</Header>
            <ListItem>
              Takes short and long positions (profit in up or down markets!)
            </ListItem>
            <ListItem>
              Analyzes market conditions using real-time price action, as well
              as live and lagging indicators.
            </ListItem>
            <ListItem>
              Algorithm determines custom support and resistance levels.
            </ListItem>
            <ListItem>Risk and adaptive money management.</ListItem>
            <ListItem>
              Secures profits as initial target is achieved while allowing for
              maximum profit potential on winning trades.
            </ListItem>
            <ListItem>
              After the first trade target is achieved, half of your position
              remains open and the stop loss is now where you entered your
              trade. This protects profits captured from the first target
              achieved should the market reverse direction.
            </ListItem>
            <ListItem>
              Support and Resistance levels function as stop losses to exit
              losing trades.
            </ListItem>
            {/* <ListItem>Read More &#9660;</ListItem> */}
          </ListContainer>
        </Right>
      </Wrapper>
      <Bottom>Bottom</Bottom>
    </Container>
  );
};

export default GraphicList;
