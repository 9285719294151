import { useState } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { mobile } from "./utilities/responsive";

import archLogo from "./assets/header-logo.png";
import MenuIcon from "@mui/icons-material/Menu";
import MobileMenu from "./MobileMenu";

const Container = styled.div`
  height: 75px;
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: #244bd8;
  padding: 0px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.24);
  ${mobile({ height: "75px", position: "sticky" })}
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  align-items: start;
`;

const Center = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-family: "Comfortaa", cursive;
  color: white;
  ${mobile({ display: "none" })}
`;

const Logo = styled.img`
  height: 40px;
  width: auto;
  ${mobile({ height: "35px" })}
`;

const Right = styled.div`
  flex: 1;
  font-family: "Comfortaa", cursive;
  color: white;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const DemoButton = styled.a`
  color: #244bd8;
  background-color: white;
  text-decoration: none;
  border-radius: 25px;
  padding: 12px 20px;
  font-family: "Comfortaa", cursive;
  font-weight: 700;
  font-size: 18px;
  cursor: pointer;
  ${mobile({ display: "none" })}
`;

const IconContainer = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  background-color: rgba(10, 22, 86, 0.7);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  ${mobile({ display: "flex" })}
`;

const NavItem = styled(Link)`
  font-size: 16;
  cursor: pointer;
  text-decoration: none;
  font-weight: 700;
  color: inherit;
  align-items: center;
  justify-content: center;
  /* border-bottom-style: solid;
  border-bottom-width: 1.5px; */
  /* padding-bottom: 3px; */
  ${mobile({ fontSize: "16px" })}

  &.active {
    color: #ff94a4;
  }
`;

function App() {
  const [displayBox, setDisplayBox] = useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDisplayBox({ ...displayBox, [anchor]: open });
  };

  const location = useLocation();

  return (
    <>
      <Container>
        <Left>
          <NavItem to="/">
            <Logo src={archLogo} alt="logo" />
          </NavItem>
        </Left>
        <Center>
          {/* <NavItem className={location.pathname === "/" && "active"} to="/">
            Home
          </NavItem>
          <NavItem
            className={location.pathname === "/bots" && "active"}
            to="/bots"
          >
            Trading Bots
          </NavItem>
          <NavItem
            className={location.pathname === "/market" && "active"}
            to="/market"
          >
            Marketplace
          </NavItem>
          <NavItem
            className={location.pathname === "/support" && "active"}
            to="/support"
          >
            Support
          </NavItem> */}
        </Center>
        <Right>
          <DemoButton
            href="https://calendly.com/botsupport-kfm/30min"
            rel="noopener noreferrer"
            target="_blank"
          >
            Schedule Demo
          </DemoButton>
          <IconContainer
            onClick={toggleDrawer("right", true)}
            style={{
              color: "#fff",
              fontSize: "24px",
              cursor: "pointer",
            }}
          >
            <MenuIcon />
          </IconContainer>
        </Right>
        <MobileMenu
          anchor={"right"}
          displayBox={displayBox}
          toggleDrawer={toggleDrawer}
        />
      </Container>
    </>
  );
}

export default App;
