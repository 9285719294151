import styled from "styled-components";
import { mobile } from "../../../utilities/responsive";
import { fontFamily, headerFontFamily } from "../../../utilities/theme";
import botImage from "../../../assets/botImage.png";
import spacebg from "../../../assets/spacebg.jpg";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #244bd8;
  width: 75vw;
  max-width: 1350px;
  margin: auto;
  height: auto;
  margin-bottom: 100px;
  margin-top: 100px;
  padding: 50px 0;

  ${mobile({ width: "100vw" })};
`;

const Header = styled.h3`
  font-size: 46px;
  font-family: ${headerFontFamily};
  color: white;
  text-align: center;
  padding: 0 10px;

  ${mobile({ fontSize: "32px" })}
`;

const BotsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  ${mobile({ flexDirection: "column" })}
`;

const Column = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px 10px;
  flex: 1;
  margin: 10px 20px;
  height: 38vh;
  border-radius: 25px;
  background-color: rgba(213, 213, 213, 0.12);
  max-width: 375px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  ${mobile({ paddingBottom: "25px" })}
`;

const BorderlessColumn = styled.div`
  display: flex;
  align-items: left;
  justify-content: left;
  flex-direction: column;
  padding: 30px 10px;
  flex: 1;
  margin: 0 20px;
  height: 38vh;
  max-width: 375px;

  ${mobile({
    borderRadius: "25px",
    backgroundColor: "rgba(213, 213, 213, 0.12)",
    boxShadow: "0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);",
  })};
`;

const ColumnHeader = styled.h4`
  font-size: 36px;
  font-family: ${fontFamily};
  color: white;
  text-align: left;

  ${mobile({ fontSize: "28px", textAlign: "center", padding: "10px" })}
`;

const ColumnDesc = styled.span`
  font-size: 16px;
  font-family: ${fontFamily};
  color: white;
  margin: 10px 0;
  text-align: left;
  ${mobile({ textAlign: "center", padding: "10px" })}
`;

const BotImage = styled.img`
  max-width: 300px;
  ${mobile({ width: "75%" })}
`;

const DescTitle = styled.span`
  font-size: 26px;
  letter-spacing: 1.25px;
  font-family: ${headerFontFamily};
  color: #ff94a4;
  margin: 10px 0;
`;

const Desc = styled.span`
  font-size: 15px;
  font-family: ${fontFamily};
  color: white;
  margin: 10px 0;
  text-align: center;
  width: 70%;
  ${mobile({ width: "100%" })}
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Button = styled.button`
  color: #244bd8;
  background-color: white;
  text-decoration: none;
  border-radius: 25px;
  padding: 12px 20px;
  font-family: ${fontFamily};
  font-weight: 700;
  font-size: 16px;
  width: 150px;
  cursor: pointer;
  margin: 10px 10px;
`;

const BuyButton = styled.button`
  color: #244bd8;
  background-color: white;
  text-decoration: none;
  border-radius: 25px;
  padding: 12px 20px;
  font-family: ${fontFamily};
  font-weight: 700;
  font-size: 16px;
  width: 150px;
  cursor: pointer;
  margin: 10px 10px;
`;

const TraderProfile = () => {
  return (
    <>
      <Container id="new-trader">
        <Header>
          For <span style={{ color: "#ff94a4" }}>Just Getting Started</span>{" "}
          Investors we recommend:
        </Header>
        <BotsWrapper>
          <Column>
            <BotImage src={botImage} />
            <DescTitle>Micro S&P Bots</DescTitle>
            <Desc>
              This line of starter bots will automate trades for micro S&P
              future contract(s).
            </Desc>
            <ButtonContainer>
              <Button>Learn More</Button>
              <BuyButton>Subscribe</BuyButton>
            </ButtonContainer>
          </Column>
          <BorderlessColumn>
            <ColumnHeader>Micro S&P 500 Pivot Bots</ColumnHeader>
            <ColumnDesc>
              Designed for traders with a smaller capital amount who are looking
              to slowly grow over time, the Micro S&P 500 bot is an incredible
              tool for anyone looking to get started.
            </ColumnDesc>
            <ColumnDesc>
              You can subscribe to bots on a month by month basis and receive a
              14 day free trial to ensure our Micro S&P bots are right for you.
            </ColumnDesc>
            <ColumnDesc>Start your trial for free today!</ColumnDesc>
          </BorderlessColumn>
        </BotsWrapper>
      </Container>
      <Container id="experienced-trader">
        <Header>
          For Investors with{" "}
          <span style={{ color: "#ff94a4" }}>Some Experience</span> we
          recommend:
        </Header>
        <BotsWrapper>
          <Column>
            <BotImage src={botImage} />
            <DescTitle>Mini S&P Bots</DescTitle>
            <Desc>
              This line of bots will automate trades for mini S&P future
              contract(s).
            </Desc>
            <ButtonContainer>
              <Button>Schedule Demo</Button>
              <BuyButton>Contact Sales</BuyButton>
            </ButtonContainer>
          </Column>
          <BorderlessColumn>
            <ColumnHeader>Mini S&P 500 Pivot Bots</ColumnHeader>
            <ColumnDesc>
              Designed for experienced traders who aspire to day trade. This
              Pivot Bot evaluates the market, establishes custom support and
              resistance levels and identifies ideal entry and exit points.
            </ColumnDesc>
            <ColumnDesc>
              With adaptive money management, the Pivot Bots take profits along
              the way to avoid giving money back on winning trades.
            </ColumnDesc>
            <ColumnDesc>
              Schedule a demo or contact our sales team to get started with
              these advanced trading bots.
            </ColumnDesc>
          </BorderlessColumn>
        </BotsWrapper>
      </Container>
      <Container id="expert-trader">
        <Header>
          For <span style={{ color: "#ff94a4" }}>Sophisticated Investors</span>{" "}
          we recommend:
        </Header>
        <BotsWrapper>
          <Column>
            <BotImage src={botImage} />
            <DescTitle>Mini S&P Bots</DescTitle>
            <Desc>
              This line of bots will automate trades for mini S&P future
              contract(s).
            </Desc>
            <ButtonContainer>
              <Button>Schedule Demo</Button>
              <BuyButton>Contact Sales</BuyButton>
            </ButtonContainer>
          </Column>
          <BorderlessColumn>
            <ColumnHeader>Mini S&P 500 Pivot Bots</ColumnHeader>
            <ColumnDesc>
              Designed for sophisticated High Net Worth traders looking for a
              cutting edge tool. This Pivot Bot evaluates the market,
              establishes custom support and resistance levels and identifies
              ideal entry and exit points.
            </ColumnDesc>
            <ColumnDesc>
              With adaptive money management, the bot takes profits along the
              way to avoid giving money back on winning trades.
            </ColumnDesc>
            <ColumnDesc>
              Schedule a demo or contact our sales team to get started with our
              top end trading bots.
            </ColumnDesc>
          </BorderlessColumn>
        </BotsWrapper>
      </Container>
    </>
  );
};

export default TraderProfile;
