import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  AccountCircleOutlined,
  ContactSupportOutlined,
  Facebook,
  GavelOutlined,
  HomeOutlined,
  Instagram,
  LandscapeOutlined,
  LinkedIn,
  MailOutline,
  ShieldOutlined,
  SmartToyOutlined,
  StorefrontOutlined,
  Twitter,
  WorkOutline,
} from "@mui/icons-material";
import { mobile } from "./utilities/responsive";
import { fontFamily } from "./utilities/theme";

const Container = styled.div`
  display: flex;
  ${mobile({ flexDirection: "column" })}
`;
const Left = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const Logo = styled.h3`
  font-weight: 500;
  font-size: 32px;
  font-family: ${fontFamily};
`;

const Desc = styled.p`
  margin: 20px 0px;
  font-family: ${fontFamily};
`;

const SocialContainer = styled.div`
  display: flex;
`;

const SocialIcon = styled.a`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: white;
  background-color: #${(props) => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  cursor: pointer;
`;

const Center = styled.div`
  flex: 1;
  padding: 20px;
  /* ${mobile({ display: "none" })} */
`;

const Title = styled.h3`
  margin-bottom: 30px;
  font-weight: 600;
  font-family: ${fontFamily};
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
`;

const ListItem = styled.li`
  width: 50%;
  margin-bottom: 10px;
  align-items: center;
  display: flex;
  font-family: ${fontFamily};
`;

const Right = styled.div`
  flex: 1;
  padding: 20px;
`;

const ContactItem = styled.a`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  color: inherit;
  font-family: ${fontFamily};
`;

const ContactItemLink = styled(Link)`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  color: inherit;
  font-family: ${fontFamily};
`;

const Payment = styled.img`
  display: flex;
  width: 50%;
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
`;

const Footer = () => {
  return (
    <Container>
      <Left>
        <Logo>Arch Public</Logo>
        <Desc>
          Arch Public provides the easiest path for brands, creators and fans to
          participate and benefit from the future of the internet today. We make
          marketplaces that work for everyone.
        </Desc>
        <SocialContainer>
          <SocialIcon color="3B5999">
            <Facebook />
          </SocialIcon>
          <SocialIcon color="E4405F">
            <Instagram />
          </SocialIcon>
          <SocialIcon color="55ACEE">
            <Twitter />
          </SocialIcon>
          <SocialIcon color="0b66c3"
                    href="https://www.linkedin.com/company/arch-public/"
                    rel="noopener noreferrer"
                    target="_blank">
            <LinkedIn />
          </SocialIcon>
        </SocialContainer>
      </Left>
      <Center>
        <Title>Navigation</Title>
        <List>
          <ListItem>
            <StyledLink to="/">
              <HomeOutlined style={{ marginRight: "5px" }} />
              Home
            </StyledLink>
          </ListItem>
          <ListItem>
            <StyledLink to="/bots">
              <SmartToyOutlined style={{ marginRight: "5px" }} />
              Trading Bots
            </StyledLink>
          </ListItem>
          <ListItem>
            {/* <StyledLink to="/market"> */}
              <StorefrontOutlined style={{ marginRight: "5px" }} />
              Marketplace
            {/* </StyledLink> */}
          </ListItem>
          <ListItem>
            {/* <StyledLink to="/support"> */}
              <ContactSupportOutlined style={{ marginRight: "5px" }} />
              FAQ
            {/* </StyledLink> */}
          </ListItem>
          <ListItem>
            <AccountCircleOutlined style={{ marginRight: "5px" }} />
            Your Account
          </ListItem>
          <ListItem>
            <GavelOutlined style={{ marginRight: "5px" }} />
            Terms of Service
          </ListItem>
          <ListItem>
            <ShieldOutlined style={{ marginRight: "5px" }} />
            Privacy Policy
          </ListItem>
        </List>
      </Center>
      <Right>
        <Title>Contact</Title>
        {/* <ContactItemLink to="/support"> */}
          {" "}
          {/* <WorkOutline style={{ marginRight: "10px" }} /> */}
          {/* FAQ/Support */}
        {/* </ContactItemLink> */}
        <ContactItem href="mailto:support@archpublic.io">
          {" "}
          <MailOutline style={{ marginRight: "10px" }} />
          support@archpublic.io
        </ContactItem>
        {/* <ContactItem
          href="https://avax.network"
          rel="noopener noreferrer"
          target="_blank"
        >
          {" "}
          <LandscapeOutlined style={{ marginRight: "10px" }} />
          Built on Avalanche
        </ContactItem> */}
        <Payment src="https://i.ibb.co/Qfvn4z6/payment.png" />
      </Right>
    </Container>
  );
};

export default Footer;
