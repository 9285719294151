import { useState } from "react";
import styled from "styled-components";
import { mobile } from "../../../utilities/responsive";

const QuestionWrapper = styled.div`
  width: 50vw;
  max-width: 800px;
  border-bottom: 1px solid #dee4f1;
  margin: 0 auto;
  padding: 1rem;
  transition: 1s;

  ${mobile({ width: "95vw" })}
`;

const QuestionContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;

  svg {
    width: 1rem;
    height: 1.5rem;
    fill: #747b81;
  }

  svg.active {
    transform: rotate(180deg);
  }

  svg:hover {
    opacity: 0.8;
  }
`;

const BoldText = styled.span`
  font-size: 20px;
  font-weight: 400;
  color: #212943;
  text-align: left;

  ${mobile({ fontSize: "14px" })}
`;

const Text = styled.span`
  display: none;

  &.active {
    display: block;
    color: #9ea6ae;
    text-align: left;
    padding-top: 1rem;
    font-weight: light;
    font-size: 0.9rem;
    height: 0%;
    animation: slidein 0.4s;
    animation-fill-mode: forwards;

    ${mobile({ fontSize: "0.8rem" })}
  }

  @keyframes slidein {
    from {
      opacity: 0.3;
      transform: translateY(-20%);
    }
    to {
      opacity: 1;
      transform: translateY(0%);
    }
  }
`;

const Button = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-left: 10px;

  &:focus {
    outline: none;
  }
`;

const Question = ({ id, question, answer }) => {
  const [isActive, setActive] = useState(false);
  const handleClick = (id) => {
    setActive(!isActive);
  };
  return (
    <QuestionWrapper onClick={() => handleClick(id)} key={id} id={id}>
      <QuestionContainer>
        <BoldText>{question}</BoldText>
        <Button>
          <svg
            className={isActive ? "active" : ""}
            viewBox="0 0 320 512"
            width="100"
            title="angle-down"
          >
            <path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z" />
          </svg>
        </Button>
      </QuestionContainer>
      <Text className={isActive ? "answer active" : "answer"}>{answer}</Text>
    </QuestionWrapper>
  );
};

export default Question;
