import styled from "styled-components";

import spacebg from "../../../assets/spacebg.jpg";
import avaxLogo from "../../../assets/avaxLogo.png"

import { fontFamily, headerFontFamily } from "../../../utilities/theme";
import { mobile } from "../../../utilities/responsive";

const Container = styled.div`
  background-image: url(${spacebg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: black;
  height: 70vh;
  width: 100vw;

  ${mobile({ backgroundPosition: "center", height: "75vh" })}
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const Header = styled.div`
  font-size: 55px;
  align-self: center;
  justify-self: center;
  text-align: center;
  text-transform: uppercase;
  color: white;
  font-family: ${headerFontFamily};
  ${mobile({ fontSize: "42px", marginTop: "0" })}
`;

const Desc = styled.div`
  font-size: 20px;
  text-align: center;
  font-weight: 500;
  font-family: ${fontFamily};
  line-height: 25px;
  color: white;
  width: 50%;

  animation-name: fadeInLeft;
  animation-delay: 0.1s;
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-fill-mode: both;
  ${mobile({ textAlign: "center", fontSize: "16px", width: "80%" })}


  @keyframes fadeInLeft {
    0% {
      opacity: 0;
      transform: translateX(-20px);
    }

    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
`;

const StyledImage = styled.img`
  width: auto;
  height: 33vh;

`;

const BuiltWith = () => {
  return (
    <Container>
      <Wrapper>
        <Header>Built Better.</Header>
          <StyledImage src={avaxLogo} alt="avax-logo" />
            <Desc>
              Avalanche aims to address the blockchain trilemma of scalability,
              security and decentralization (flexibility) thanks to its unique
              Proof of Stake mechanism. It supports smart contracts to run
              decentralized applications (dApps) on its network.
            </Desc>
      </Wrapper>
    </Container>
  );
};

export default BuiltWith;
