import { BrowserRouter, Routes, Route } from "react-router-dom";
import AboutPage from "./pages/About/AboutPage";
import TradingBots from "./pages/Bots/TradingBots";
import ComingSoon from "./pages/ComingSoon";
import Support from "./pages/Support/Support";
import Terms from "./pages/Terms/Terms";
import ScrollToTop from "./utilities/scrollToTop";



const App = () => {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          {/* <Route path="/" element={<AboutPage />} /> */}
          <Route path="/" element={<TradingBots />} />
          <Route path="/bots" element={<TradingBots />} />
          <Route path="/market" element={<ComingSoon />} />
          <Route path="/support" element={<Support />} />
          <Route path="/terms" element={<Terms />} />
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
};
export default App