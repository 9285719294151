import Footer from "../../Footer";
import Navbar from "../../Navbar";

import styled from "styled-components";
import { mobile } from "../../utilities/responsive";
import ContactForm from "./components/ContactForm";
import Question from "./components/Question";
import NewsLetter from "../../Newsletter";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  scroll-snap-align: start;
  scroll-snap-stop: always;

  flex-direction: column;
`;

const Top = styled.div`
  display: flex;
  flex: 3;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Header = styled.h3`
  font-size: 28px;
  font-weight: 400;

  ${mobile({ marginTop: "50px" })}
`;

const Contact = styled.div`
  margin-top: 50px;

  ${mobile({ marginTop: "25px" })}
`;

const questions = [
  {
    id: 1,
    question: "Why are your bots only available as subscriptions?",
    answer:
      "The market enviroment is constantly evolving and changing. In order to stay competitive we need to constantly update and optimize our bots. Subscriptions help us keep the lights on so our developers can continuously provide you with the best possible product.",
  },
  {
    id: 2,
    question: "What Brokerage platforms do you support?",
    answer: `Currently you can use your bot with TradeStation and NinjaTrader, and we're actively working on adding support for more licensed US Brokerages, so stay tuned for updates!`,
  },
  {
    id: 3,
    question:
      "If your trading bots really work, why not just run them yourselves and get rich?",
    answer:
      "We do! Our employees are running the exact same bots that we sell. So we're incentived to build the best possible trading bots - because we use them too!",
  },
  {
    id: 4,
    question: "Are trading bots securities? What is risk capital?",
    answer:
      "DISCLAIMER: We are not financial advisors. We do not sell securities. All results are hypothetical. Futures and forex trading contains substantial risk and is not for every investor. An investor could potentially lose all or more than the initial investment. Risk capital is money that can be lost without jeopardizing ones' financial security or lifestyle. Only risk capital should be used for trading and only those with sufficient risk capital should consider trading. Past performance is not necessarily indicative of future results.",
  },
  {
    id: 5,
    question: "Do you guarantee profits?",
    answer:
      "No representation is being made that any account will or is likely to achieve profits or losses similar to those shown;. One of the limitations of hypothetical performance results is that hypothetical trading does not involve financial risk, and no hypothetical trading record can completely account for the impact of financial risk of actual trading.",
  },
];

const Support = () => {
  return (
    <>
      <Navbar />
      <Container>
        <Top>
          <Header>FAQ</Header>
          {questions.map((item) => (
            <Question
              id={item.id}
              question={item.question}
              answer={item.answer}
            />
          ))}
          <Contact>
            <ContactForm />
          </Contact>
        </Top>
      </Container>
      <NewsLetter />
      <Footer />
    </>
  );
};

export default Support;
