import { useState } from "react";
import styled from "styled-components";
import { landscape, mobile } from "./utilities/responsive";
import { headerFontFamily } from "./utilities/theme";

const Container = styled.div`
  height: 45vh;
  background-color: #244bd8;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${mobile({ height: "50vh" })}
  ${landscape({ height: "75vh"})}
  
`;

const Title = styled.div`
  font-size: 55px;
  text-transform: uppercase;
  color: white;
  font-family: ${headerFontFamily};
  ${mobile({ fontSize: "50px" })}
`;

const Desc = styled.div`
  font-size: 24px;
  font-weight: 300;
  margin: 25px 0;
  color: white;
  animation-name: fadeInLeft;
  animation-delay: 0.1s;
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-fill-mode: both;
  width: 30%;
  text-align: center;
  ${mobile({ textAlign: "center", fontSize: "20px", width: "90%" })}

  @keyframes fadeInLeft {
    0% {
      opacity: 0;
      transform: translateX(-20px);
    }

    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
`;

const InputContainer = styled.form`
  margin-top: 10px;
  width: 40%;
  min-height: 40px;
  background-color: white;
  padding: 10px;
  border-radius: 50px;
  display: flex;
  justify-content: space-between;
  border: 1px solid lightgray;
  ${mobile({ width: "80%" })}
`;

const Input = styled.input`
  border: none;
  flex: 8;
  padding-left: 20px;
`;

const Button = styled.button`
  flex: 1;
  border: none;
  border-radius: 50px;
  background-color: #244bd8;
  color: white;
  cursor: pointer;
  transition: all 0.5s ease;
  min-width: 100px;
  min-height: 50px;

  &:hover {
    color: lightgray;
  }
  &:disabled {
    background-color: darkgray;
    cursor: not-allowed;
  }
`;


const NewsLetter = () => {
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  // const [error, setError] = useState(false);
  // const [open, setOpen] = useState(true);

  const FORM_URL = "https://app.convertkit.com/forms/3588371/subscriptions"

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData(e.target)

    try {
      const response = await fetch(FORM_URL, {
        method: "post",
        body: data,
        headers: {
          accept: "application/json",
        },
      })

      setEmail("")
      const json = await response.json();
      
      if (json.status === "success") {
        setSubmitted(true);
        return;
      }
    } catch (err) {
      // setError(true);
      console.log(err);
    }
  };

  return (
    <Container>
      <Title>Don't Miss Out!</Title>
      <Desc>
        Sign up for our newsletter and be the first to know about our exclusive
        new releases!
      </Desc>
      
      <InputContainer onSubmit={handleSubmit}>
        <Input
          placeholder="E-mail"
          name="email_address"
          required
          type="email"
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button type="submit" disabled={submitted}>
          Sign Up
        </Button>
      </InputContainer>
      {submitted && (
        <Desc
          style={{ fontSize: "18px", marginTop: "10px", marginBottom: "0" }}
        >
          Thank you for registering!
        </Desc>
      )}

    </Container>
  );
};

export default NewsLetter;
