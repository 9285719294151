import styled from "styled-components";
import { mobile } from "../../../utilities/responsive";

const Container = styled.div`
  display: flex;
  position: relative;
  width: 100vw;
  height: 30px;
  margin: auto;
  background-color: #fe96a3;
  overflow: hidden;
  z-index: 1;

  ${mobile({ height: "10px"})}
`;

const Wrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  white-space: nowrap;
`;


const MarqueeNoScroll = () => {
  return (
    <Container>
      <Wrapper>
        
      </Wrapper>
    </Container>
  );
};

export default MarqueeNoScroll;
