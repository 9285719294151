import styled from "styled-components";
import { mobile } from "../../../utilities/responsive";
import sparksOne from "../../../assets/Sparks.png";
import sparksTwo from "../../../assets/Sparks2.png";
import sparksThree from "../../../assets/Sparks3.png";
import { headerFontFamily } from "../../../utilities/theme";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #fdfcf2;
  padding-bottom: 100px;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 33%;

  ${mobile({ width: "66%" })}
`;

const Middle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 33%;

  ${mobile({ width: "66%" })}
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 33%;

  ${mobile({ width: "66%" })}
`;

const Header = styled.h2`
  font-size: 48px;
  font-family: ${headerFontFamily};
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;

  animation-name: fadeInLeft;
  animation-delay: 0.1s;
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-fill-mode: both;
  
  ${mobile({ fontSize: "28px" })}
`;

const Icon = styled.img`
  margin-top: 20%;
`;

const Text = styled.span`
  font-size: 18px;
  font-family: "Comfortaa", cursive;
  text-align: center;
  line-height: 25px;
  animation-name: fadeInLeft;
  animation-delay: 0.1s;
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-fill-mode: both;

  ${mobile({ fontSize: "14px", lineHeight: "20px" })}

  @keyframes fadeInLeft {
    0% {
      opacity: 0;
      transform: translateX(-20px);
    }

    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
`;

const About = () => {
  return (
    <Container>
      <Top>
        <Icon src={sparksOne} alt="spark icon" />
        <Header>We're Arch Public.</Header>
        <Text>
          We’re creating a platform where brands, creators, and digital
          enthusiasts can easily access Web3 technologies and participate in the
          future of the Internet.
        </Text>
      </Top>
      <Middle>
        <Icon src={sparksTwo} alt="spark icon" />
        <Header>Web3 For Everyone.</Header>
        <Text>
          Web3 is a digital revolution democratizing the Internet while
          preserving user privacy through the power of blockchain technology. It
          unlocks the ability to painlessly handle transactions, manage assets,
          and add value for everyone.
        </Text>
      </Middle>
      <Bottom>
        <Icon src={sparksThree} alt="spark icon" />
        <Header>What is Utility?</Header>
        <Text>
          The magic of utility in NFTs and Web3 unlocks privileges, access,
          rights and rewards like never before. That puts more power where it
          should be: with creators. With our utility-based NFTs and NFT
          marketplaces, we’re creating real-world value and experiences for
          brands, creators and fans.
        </Text>
      </Bottom>
    </Container>
  );
};

export default About;
