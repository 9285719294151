import { useState } from "react";
import styled from "styled-components";
import { mobile } from "../../../utilities/responsive";
import ContactModal from "../../../modals/ContactModal";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Text = styled.button`
  width: 200px;
  height: 50px;
  color: white;
  font-size: 20px;
  font-weight: 500;
  background-color: #244bd8;
  border-radius: 25px;
  border: none;
  cursor: pointer;

  ${mobile({
    width: "200px",
    fontSize: "18px",
    height: "50px",
    margin: "20px 0",
  })}
`;

const ContactForm = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Container>
      {open && <ContactModal open={open} onClose={handleClose} />}
      <Text onClick={handleOpen}>Contact Support</Text>
    </Container>
  );
};

export default ContactForm;
