// import Footer from "../../Footer";
import Navbar from "../../Navbar";

import styled from "styled-components";
import { mobile } from "../../utilities/responsive";
import NewsLetter from "../../Newsletter";
import { fontFamily, headerFontFamily } from "../../utilities/theme";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Header = styled.h2`
  font-size: 50px;
  font-weight: 400;
  text-transform: uppercase;
  font-family: ${headerFontFamily};

  ${mobile({ marginTop: "50px", marginLeft: "10px" })};
`;

const Content = styled.div`
  display: flex;
  text-align: left;
  flex-direction: column;
  width: 50%;
  max-width: 1000px;
  margin-bottom: 50px;
  ${mobile({ width: "100%" })}
`;

const OrderedList = styled.ol`
${mobile({marginRight: "5%"})}`;

const ContentHeader = styled.div`
  font-size: 32px;
  font-weight: 400;
  text-transform: uppercase;
  font-family: ${headerFontFamily};
  margin: 10px 0;
  ${mobile({ fontSize: "24px" })}
`;

const ListItem = styled.li`
  font-size: 16px;
  font-weight: 200;
  text-transform: none;
  font-family: ${fontFamily};
  padding: 10px;
  ${mobile({ fontSize: "12px" })}
`;

const Terms = () => {
  return (
    <>
      <Navbar />
      <Container>
        <Top>
          <Header>Terms and Conditions</Header>
        </Top>
        <Content>
          <OrderedList>
            <ContentHeader>License </ContentHeader>
            <ListItem>
              Under this Software License Agreement (the “Agreement”), Arch
              Public, Inc. (the “Vendor”) grants to the user (the “Licensee”) a
              non-exclusive and non-transferable license (the “License”) to use
              Arch Public Trading Strategy (the “Software”).
            </ListItem>
            <ListItem>
              “Software” includes the executable computer programs and any
              related printed, electronic and online documentation and any other
              files that may accompany the product.
            </ListItem>
            <ListItem>
              Title, copyright, intellectual property rights and distribution
              rights of the Software remain exclusively with the Vendor.
              Intellectual property rights include the look and feel of the
              Software. This Agreement constitutes a license for use only and is
              not in any way a transfer of ownership right to the Software.
            </ListItem>
            <ListItem>
              The Software may be loaded onto no more than one computer. A
              single copy may be made for backup purposes only.
            </ListItem>
            <ListItem>
              The rights and obligations of this Agreement are personal rights
              granted to the Licensee only. The Licensee may not transfer or
              assign any of the rights or obligations granted under this
              Agreement to any other person or legal entity. The Licensee may
              not make available the Software for use by one or more third
              parties.
            </ListItem>
            <ListItem>
              The Software may not be modified, reverse engineered, or
              de-compiled in any manner through current or future available
              technologies.
            </ListItem>
            <ListItem>
              Failure to comply with any of the terms under the License section
              will be considered a material breach of this Agreement.
            </ListItem>
            <ContentHeader>License Fee </ContentHeader>
            <ListItem>
              The original purchase price paid by the Licensee will constitute
              the entire license fee and is the full consideration for this
              Agreement.
            </ListItem>
            <ContentHeader>Limitation of Liability</ContentHeader>
            <ListItem>
              The Software provided by the Vendor and accepted by the Licensee
              “as is”. Liability of the Vendor will be limited to a maximum of
              the original purchase price of the Software. The Vendor will not
              be liable for any general, special, incidental or consequential
              damages including but not limited to, loss of production, loss of
              profits, loss of revenue, loss of data, or any other business or
              economic disadvantage suffered by the Licensee arising out of the
              use of failure to use the Software. The Software will be run on the
              hardware specifications set forth by TradeStation requirements.
            </ListItem>
            <ListItem>
              The Vendor makes no warranty expressed or implied regarding the
              fitness of the Software for a particular purpose or that the
              Software will be suitable or appropriate for the specific
              requirements of the Licensee.
            </ListItem>
            <ListItem>
              The Vendor Does not warrant that use of the Software will be
              uninterrupted or error-free. The Licensee accepts that software in
              general is prone to bugs and flaws within an acceptable level as
              determined by the industry.
            </ListItem>
            <ContentHeader>Warrants and Representations</ContentHeader>
            <ListItem>
              The Vendor warrants and represents that it is the copyright holder
              of the Software. The Vendor warrants and represents that granting
              the license to use this Software is not in violation of any other
              agreement, copyright, or applicable statute.
            </ListItem>
            <ContentHeader>Acceptance</ContentHeader>
            <ListItem>
              All terms, conditions and obligations of this Agreement will be
              deemed to be accepted by the Licensee (“Acceptance”) on
              installation of the Software.
            </ListItem>
            <ContentHeader>Term</ContentHeader>
            <ListItem>
              The term of this Agreement will begin on Acceptance and will
              continue for a period of one year. At the end of the term of this
              Agreement the Licensee must destroy all copies of the Software in
              their possession.
            </ListItem>
            <ContentHeader>Termination</ContentHeader>
            <ListItem>
              This Agreement will be terminated and the License forfeited where
              the Licensee has failed to comply with any of the terms of this
              Agreement or is in breach of this Agreement. On termination of
              this Agreement for any reason, the Licensee will promptly destroy
              the Software.
            </ListItem>
            <ContentHeader>Force Majeure</ContentHeader>
            <ListItem>
              The Vendor will be free of liability to the Licensee where the
              Vendor is prevented from executing its obligations under this
              Agreement in whole or in part due to Force Majeure, such as
              earthquake, typhoon, flood, fire, and war or any other unforeseen
              and uncontrollable event where the Vendor has taken any and all
              appropriate action to mitigate such an event.
            </ListItem>
            <ContentHeader>Governing Law</ContentHeader>
            <ListItem>
              The Parties to this Agreement submit to the jurisdiction of the
              courts of the State of Delaware for the enforcement of this
              Agreement or any arbitration award or decision arising from this
              Agreement. This Agreement will be enforced or construed according
              to the laws of the State of Delaware.
            </ListItem>
            <ContentHeader>Miscellaneous</ContentHeader>
            <ListItem>
              This Agreement can only be modified in writing signed by both
              Vendor and Licensee
            </ListItem>
            <ListItem>
              This Agreement does not create or imply any relationship in agency
              or partnership between the Vendor and Licensee.
            </ListItem>
            <ListItem>
              Headings are inserted for the convenience of parties only and are
              not to be considered when interpreting this Agreement. Words in
              singular mean and include the plural and vice versa. Words in the
              masculine gender include the feminine gender and vice versa. Words
              in the neutral gender include the masculine gender and the
              feminine gender and vice versa.
            </ListItem>
            <ListItem>
              If any term, covenant, condition or provision of this Agreement is
              held by a court of competent jurisdiction to be invalid, void or
              unenforceable, it is the parties’ intent that such provision be
              reduced in scope by the court only to the extent deemed necessary
              by that court to render the provision reasonable and enforceable
              and the remainder of the provisions of this Agreement will in no
              way be affected, impaired, or invalidated as a result.
            </ListItem>
            <ListItem>
              This Agreement and the terms and conditions contained in this
              Agreement apply to and are binding upon the Vendors' successors
              and assigns.
            </ListItem>
            <ContentHeader>Notices</ContentHeader>
            <ListItem>
              All notices to the Vendor under this Agreement are to be provided
              at the following address: Arch Public, Inc.: 251 Little Falls Dr.
              Wilmington, DE 19808
            </ListItem>
            <ContentHeader>Software Disclaimer</ContentHeader>
            <ListItem>
              There is a very high degree of risk involved in trading. Past
              results are not indicative of future returns. Arch Public, Inc and
              all individuals affiliated with it assume no responsibility for
              your trading and investment results. The indicators, columns,
              articles, and all other features associated with Arch Public, Inc
              are for educational purposes only and should not be construed as
              investment advice. Your use of all indicators, strategies,
              columns, articles, and all other features is entirely at your own
              risk and it is your sole responsibility to evaluate the accuracy,
              completeness, and usefulness of the information. Please note, we
              are not and do not claim to be financial advisors and recommend
              consulting a professional advisor before trading your own money.
            </ListItem>
            <ContentHeader>Class Action Waiver</ContentHeader>
            <ListItem>
              Licensor and licensee agree that each may bring claims against the
              other only in their individual capacities, and not as a plaintiff
              or class member in any purported class or representative
              proceeding. Further, regarding the Parties’ dispute, whether
              resolved or unresolved, it must be settled independent of another
              person’s claims.
            </ListItem>
          </OrderedList>
        </Content>
      </Container>
      <NewsLetter />
      {/* <Footer /> */}
    </>
  );
};

export default Terms;
