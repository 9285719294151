import styled from "styled-components";

import { mobile } from "../../../utilities/responsive";
import sparks3 from "../../../assets/Sparks3.png";
// import sadIcon from "../../../assets/sadIcon1.png";
// import lightningIcon from "../../../assets/lightningIcon2.png";
// import squareIcon from "../../../assets/squareIcon3.png";
// import walletIcon from "../../../assets/walletIcon4.png";
// import trophyIcon from "../../../assets/trophyIcon5.png";
// import botIcon from "../../../assets/botIcon6.png";
import { headerFontFamily } from "../../../utilities/theme";
import {
  Looks3Outlined,
  LooksOneOutlined,
  LooksTwoOutlined,
} from "@mui/icons-material";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #fdfcf2;
  padding: 5% 0;

  ${mobile({ padding: "10% 0" })};
`;

const Icon = styled.img`
  width: 50px;
  margin-right: 25px;
`;

const StyledIcon = styled.span`
  margin-right: 25px;
`;

const Header = styled.h2`
  font-size: 48px;
  font-family: ${headerFontFamily};
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;

  ${mobile({ fontSize: "32px" })}
`;

const Text = styled.span`
  font-size: 16px;
  font-weight: 600;
  font-family: "Comfortaa", cursive;
  text-align: left;
  line-height: 30px;
  max-width: 750px;

  ${mobile({ fontSize: "16px", lineHeight: "25px" })}
`;

const ListWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 50%;
  max-width: 750px;

  ${mobile({ width: "90%" })}
`;

const ListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin: 25px 0;
`;

const IconListFutures = () => {
  return (
    <Container>
      <Icon
        style={{ width: "auto", marginRight: "0" }}
        src={sparks3}
        alt="star icon"
      />
      <Header>Why Trade Futures?</Header>
      <Text style={{ fontStyle: "italic", textAlign: "center" }}>
        The Pivot Strategy trades futures contracts and provides all the
        advantages inherent to trading futures.
      </Text>
      <ListWrapper>
        <ListItem>
          <StyledIcon>
            <LooksOneOutlined style={{ fontSize: "35px" }} />
          </StyledIcon>
          <Text>
            Leverage. Futures contracts are traded on margin, lowering the
            threshold of needed capital to trade every day.{" "}
          </Text>
        </ListItem>
        <ListItem>
          <StyledIcon>
            <LooksTwoOutlined style={{ fontSize: "35px" }} />
          </StyledIcon>
          <Text>
            Value. Potential for greater profits in relation to the minimum
            needed capital
          </Text>
        </ListItem>
        <ListItem>
          <StyledIcon>
            <Looks3Outlined style={{ fontSize: "35px" }} />
          </StyledIcon>
          <Text>
            Tax Benefits. Futures contracts are taxed differently through the
            60/40 rule: a certain amount of profits may be taxed at long term
            capital gain rates.
          </Text>
        </ListItem>
      </ListWrapper>
    </Container>
  );
};

export default IconListFutures;
