import styled from "styled-components";

import { mobile } from "../../../utilities/responsive";
import sparks from "../../../assets/Sparks.png";
import { headerFontFamily } from "../../../utilities/theme";
import {
  InsertEmoticonOutlined,
  SentimentSatisfiedOutlined,
  SentimentVerySatisfiedOutlined,
} from "@mui/icons-material";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #fdfcf2;
  padding: 5% 0;

  ${mobile({ padding: "10% 0" })};
`;

const Icon = styled.img`
  width: 50px;
  margin-right: 25px;
`;

const StyledIcon = styled.span`
  margin-right: 25px;
`;

const Header = styled.h2`
  font-size: 48px;
  font-family: ${headerFontFamily};
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;

  ${mobile({ fontSize: "32px" })}
`;

const Text = styled.span`
  font-size: 16px;
  font-weight: 600;
  font-family: "Comfortaa", cursive;
  text-align: left;
  max-width: 750px;
  line-height: 30px;

  ${mobile({ fontSize: "16px", lineHeight: "25px" })}
`;

const ListWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 50%;
  max-width: 750px;

  ${mobile({ width: "90%" })}
`;

const ListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin: 25px 0;
`;

const IconListArch = () => {
  return (
    <Container>
      <Icon
        style={{ width: "auto", marginRight: "0" }}
        src={sparks}
        alt="star icon"
      />
      <Header>Why Trade With Arch Public?</Header>
      <Text style={{ fontStyle: "italic", textAlign: "center" }}>
        Our mission is to help everyone become a successful day trader
      </Text>
      <ListWrapper>
        <ListItem>
          <StyledIcon>
            <SentimentSatisfiedOutlined style={{ fontSize: "35px" }} />
          </StyledIcon>
          <Text>
            Your money stays in your control and in your account. We'll never
            ask for your password or for access to your brokerage.
          </Text>
        </ListItem>
        <ListItem>
          <StyledIcon>
            <InsertEmoticonOutlined style={{ fontSize: "35px" }} />
          </StyledIcon>
          <Text>
            Our strategy trades on a licensed and regulated US brokerage
            platform:{" "}
            <a
              style={{
                fontWeight: "600",
                color: "blue",
                textDecoration: "none",
              }}
              href="https://www.tradestation.com"
              rel="noopener noreferrer"
              target="_blank"
            >
              TradeStation
            </a>
          </Text>
        </ListItem>
        <ListItem>
          <StyledIcon>
            <SentimentVerySatisfiedOutlined style={{ fontSize: "35px" }} />
          </StyledIcon>
          <Text>
            Our products have significantly outperformed the markets since our
            inception in 2021.
          </Text>
        </ListItem>
      </ListWrapper>
    </Container>
  );
};

export default IconListArch;
