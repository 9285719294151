import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { mobile } from "../../../utilities/responsive";
import { fontFamily, headerFontFamily } from "../../../utilities/theme";

const Header = styled.div`
  font-size: 48px;
  color: black;
  font-family: ${headerFontFamily};
  text-transform: uppercase;
  text-align: center;
  padding-top: 125px;
  background-color: #fdfcf2;

  ${mobile({ fontSize: "32px", paddingTop: "75px" })}
`;

const SubHeader = styled.div`
  padding: 10px 0;
  text-align: center;
  background-color: #fdfcf2;

  font-family: ${fontFamily};
  font-size: 18px;
  ${mobile({ fontSize: "14px" })}
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100vw;
  height: 25vh;
  background-color: #fdfcf2;

  ${mobile({ flexDirection: "column", padding: "75px 0" })}
`;

const Button = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20vw;
  height: 12vh;
  background-color: #244bd8;
  cursor: pointer;
  /* border-radius: 50px; */

  ${mobile({ width: "75vw", height: "25vh", margin: "10px" })}

  animation-name: fadeInLeft;
  animation-delay: 0.1s;
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-fill-mode: both;

  @keyframes fadeInLeft {
    0% {
      opacity: 0;
      transform: translateX(-20px);
    }

    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
`;

const Top = styled.div`
  font-size: 24px;
  color: #ff94a4;
  font-weight: 600;
  letter-spacing: 1.5px;
  font-family: ${headerFontFamily};
  margin: 5px 0;
  ${mobile({ fontSize: "22px", padding: "2.5px" })}
`;

const Bottom = styled.div`
  color: white;
  margin: 5px 0;
  font-family: ${fontFamily};
  ${mobile({ fontSize: "14px", padding: "2.5px" })}
`;

const Qualifier = () => {
  return (
    <>
      <Header>Find the Perfect Automation For You.</Header>
      <SubHeader>
        Get started by selecting the trader profile and capital assets that best describe you.
      </SubHeader>
      <Container>
        <Button
          onClick={() => {
            document.getElementById("new-trader").scrollIntoView();
          }}
        >
          <Top>Just Getting Started</Top>
          <Bottom>Less than $15,000</Bottom>
        </Button>
        <Button
          onClick={() => {
            document.getElementById("experienced-trader").scrollIntoView();
          }}
        >
          <Top>Some Experience</Top>
          <Bottom>$15,000 up to $150,000</Bottom>
        </Button>
        <Button>
          <Top>Sophisticated Investor</Top>
          <Bottom>Greater than $150,000</Bottom>
        </Button>
      </Container>
      <SubHeader style={{ paddingBottom: "50px" }}>
        Know exactly what bot you need? Head over to our{" "}
        <Link style={{ color: "inheirt" }} to="/market">
          marketplace
        </Link>
      </SubHeader>
    </>
  );
};

export default Qualifier;
