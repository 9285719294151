import styled from "styled-components";
import { mobile } from "../../../utilities/responsive";
import sparksOne from "../../../assets/Sparks.png";
import sparks3 from "../../../assets/Sparks3.png";

import { headerFontFamily } from "../../../utilities/theme";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #fdfcf2;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40%;
  padding-bottom: 5%;

  ${mobile({ width: "85%" })}
`;

const Header = styled.h2`
  font-size: 48px;
  font-family: ${headerFontFamily};
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;

  animation-name: fadeInLeft;
  animation-delay: 0.1s;
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-fill-mode: both;

  ${mobile({ fontSize: "28px" })}
`;

const Icon = styled.img`
  margin-top: 10%;
`;

const Text = styled.span`
  font-size: 16px;
  font-family: "Comfortaa", cursive;
  text-align: center;
  line-height: 25px;
  max-width: 750px;
  animation-name: fadeInLeft;
  animation-delay: 0.1s;
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-fill-mode: both;
  padding-bottom: 2%;

  ${mobile({ fontSize: "14px", lineHeight: "20px" })}

  @keyframes fadeInLeft {
    0% {
      opacity: 0;
      transform: translateX(-20px);
    }

    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
`;

const ButtonContainer = styled.div`
  align-items: center;
  justify-content: center;
  margin: 10% 0;
  display: none;
  ${mobile({ display: "flex" })}
`;

const DemoButton = styled.a`
  background-color: #244bd8;
  color: white;
  text-decoration: none;
  border-radius: 25px;
  padding: 15px 20px;
  font-family: "Comfortaa", cursive;
  font-weight: 700;
  font-size: 18px;
  cursor: pointer;
`;

const AboutBots = () => {
  return (
    <Container>
      <Top>
        <Icon src={sparksOne} alt="spark icon" />
        <Header>Our Commitment.</Header>
        <Text>
          Arch Public is committed to providing everyday investors with the same
          tools previously only available to hedge funds and investment banks.
          95% of people who attempt to day trade fail because they don't have
          rules or a plan - and don't stick to it even if they do. With
          automated trading, you can eliminate human emotion, giving you the
          advantage and tools to succeed. Whether you're brand new to investing
          or a seasoned daytrader, automated trading can create extraordinary
          results for you.
        </Text>
        <Icon src={sparks3} alt="spark icon" />
        <Header>Who We Are.</Header>
        <Text>
          Arch Public is a company of traders, developers and visionaries on a
          mission to level the playing field through automation. We create
          products that allow retail, professional and institutional traders to
          utilize automation and thrive in any market condition.
        </Text>
        <Text>
          Our automated trading strategies have significantly outperformed the
          markets since our launch in 2021.
        </Text>
        <ButtonContainer>
          <DemoButton
            href="https://calendly.com/botsupport-kfm/30min"
            rel="noopener noreferrer"
            target="_blank"
          >
            Schedule a Demo
          </DemoButton>
        </ButtonContainer>
      </Top>
    </Container>
  );
};

export default AboutBots;
