import styled from "styled-components";
import burstIcon from "../../../assets/burstIcon.png";
import { mobile } from "../../../utilities/responsive";

const Container = styled.div`
  display: flex;
  position: relative;
  width: 100vw;
  height: 40px;
  margin: auto;
  background-color: #fe96a3;
  overflow: hidden;
  z-index: 1;

  ${mobile({height: "20px"})}
`;

const Wrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  white-space: nowrap;
  transform: scale(2);
  transition: all 1s ease;
  & > span {
    display: flex;
    animation: scrollText 33s infinite linear;
  }
  @keyframes scrollText {
    from {
      transform: translateX(0%);
    }
    to {
      transform: translateX(-50%);
    }
  }
`;

const Icon = styled.img`
  height: 10px;
  width: auto;
  margin: 0 10px;
  ${mobile({ height: "10px" })}
`;

const Text = styled.span`
  display: flex;
  align-items: center;
  font-size: 12px;
  animation: scrollText 33s infinite linear;

  ${mobile({ fontSize: "10px" })}
`;

const Text2 = styled.span`
  display: flex;
  align-items: center;
  font-size: 12px;
  animation-delay: 15s;
  animation: scrollText 33s infinite linear;

  ${mobile({ fontSize: "10px" })}
`;

const Marquee = () => {
  return (
    <Container>
      <Wrapper>
        {/* <Text>
          <Icon src={burstIcon} alt="burst icon" /> New Release{" "}
          <Icon src={burstIcon} alt="burst icon" /> New Release{" "}
          <Icon src={burstIcon} alt="burst icon" /> New Release{" "}
          <Icon src={burstIcon} alt="burst icon" /> New Release{" "}
          <Icon src={burstIcon} alt="burst icon" /> New Release{" "}
          <Icon src={burstIcon} alt="burst icon" /> New Release{" "}
          <Icon src={burstIcon} alt="burst icon" /> New Release{" "}
          <Icon src={burstIcon} alt="burst icon" /> New Release{" "}
        </Text>
        <Text2>
          <Icon src={burstIcon} alt="burst icon" /> New Release{" "}
          <Icon src={burstIcon} alt="burst icon" /> New Release{" "}
          <Icon src={burstIcon} alt="burst icon" /> New Release{" "}
          <Icon src={burstIcon} alt="burst icon" /> New Release{" "}
          <Icon src={burstIcon} alt="burst icon" /> New Release{" "}
          <Icon src={burstIcon} alt="burst icon" /> New Release{" "}
          <Icon src={burstIcon} alt="burst icon" /> New Release{" "}
          <Icon src={burstIcon} alt="burst icon" /> New Release{" "}
        </Text2> */}
      </Wrapper>
    </Container>
  );
};

export default Marquee;
