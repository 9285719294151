import {
  ArrowBackIosNewOutlined,
  ArrowForwardIosOutlined,
} from "@mui/icons-material";
import { useState } from "react";
import styled from "styled-components";
import { landscape, mobile } from "../../../utilities/responsive";

import card1 from "../../../assets/card1.png";
import card2 from "../../../assets/card2.png";
import card3 from "../../../assets/card3.png";
import { headerFontFamily } from "../../../utilities/theme";

const Container = styled.div`
  background-color: #244bd8;
  width: 100%;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
  overflow: hidden;

  @media only screen and (min-width: 1001px) and (max-width: 1300px) {
    height: 80vh;
  }

  ${mobile({ height: "100%" })};
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 25px 0;

  @media only screen and (min-width: 1001px) and (max-width: 1300px) {
    flex-direction: column;
    justify-content: center;
  }

  ${mobile({ flexDirection: "column", justifyContent: "center" })};
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media only screen and (min-width: 1001px) and (max-width: 1300px) {
    margin-left: 0;
    width: 50%;
    align-items: center;
    margin-bottom: 50px;
  }

  ${mobile({ marginLeft: "0" })};
`;

const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 15px;

  ${mobile({ justifyContent: "center" })};
`;

const Header = styled.h2`
  font-size: 48px;
  font-family: ${headerFontFamily};
  font-weight: 500;
  text-transform: uppercase;
  text-align: left;
  margin: 10px 25px;
  color: white;
  max-width: 500px;


  ${mobile({ fontSize: "36px", textAlign: "center", margin: "10px 0" })}
  ${landscape({ fontSize: "32px" })};
`;

const Text = styled.span`
  font-size: 18px;
  font-family: "Comfortaa", cursive;
  color: white;
  text-align: left;
  line-height: 30px;
  margin: 10px 25px;
  max-width: 400px;

  @media only screen and (min-width: 1001px) and (max-width: 1300px) {
    text-align: center;
  }

  ${mobile({
    fontSize: "16px",
    lineHeight: "25px",
    textAlign: "center",
    width: "80vw",
    alignSelf: "center",
  })}
  ${landscape({ fontSize: "16px" })};
`;

const Right = styled.div`
  display: flex;
  align-items: center;
  flex: 2;
  overflow: hidden;
  max-width: 100%;
  ${mobile({ flex: "1", marginTop: "25px" })}
`;

const Card = styled.img`
  width: 350px;
  height: auto;
  margin: 0 20px;
  border-radius: 15px;
`;

const ArrowStyle = styled.div`
  width: 90px;
  height: 90px;
  background-color: #d9f2f2;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  left: ${(props) => props.direction === "left" && "25px"};
  right: ${(props) => props.direction === "right" && "25px"};
  cursor: pointer;
  z-index: 2;
  margin: 10px 10px;

  ${landscape({ display: "none" })};
  ${mobile({ width: "60px", height: "60px" })}
`;

const Carousel = styled.div`
  display: flex;
  max-width: 750px;
  transform: translateX(${(props) => (props.slideIndex + 1) * -350}px);
  transition: all 1s ease;

  @media only screen and (max-width: 860px) {
    transform: translateX(${(props) => props.slideIndex * -390}px);
    max-width: 100%;
  }
`;

const Slider = () => {
  const [slideIndex, setSlideIndex] = useState(-1);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 50;

  const onTouchStart = (e) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;

    // Yes, these work backwards
    if (isLeftSwipe) {
      handleClick("right");
    }
    if (isRightSwipe) {
      handleClick("left");
    }
  };

  const sliderItems = [
    { id: "0001", title: "card1", url: card1 },
    { id: "0002", title: "card2", url: card2 },
    { id: "0003", title: "card3", url: card3 },
  ];

  const handleClick = (direction) => {
    if (direction === "left") {
      setSlideIndex(slideIndex > -1 ? slideIndex - 1 : 1);
    } else {
      setSlideIndex(slideIndex < 1 ? slideIndex + 1 : -1);
    }
  };

  return (
    <Container>
      <Wrapper>
        <Left>
          <Header>What Makes Us Different.</Header>
          <Text>
            We’re committed to bringing the promise of web3 to the masses.
          </Text>
          <ArrowContainer>
            <ArrowStyle direction="left" onClick={() => handleClick("left")}>
              <ArrowBackIosNewOutlined />
            </ArrowStyle>
            <ArrowStyle direction="right" onClick={() => handleClick("right")}>
              <ArrowForwardIosOutlined />
            </ArrowStyle>
          </ArrowContainer>
        </Left>
        <Right
          onTouchStart={onTouchStart}
          onTouchMove={onTouchMove}
          onTouchEnd={onTouchEnd}
        >
          <Carousel slideIndex={slideIndex}>
            {sliderItems.map((item) => (
              <Card key={item.id} src={item.url} alt={item.title} />
            ))}
          </Carousel>
        </Right>
      </Wrapper>
    </Container>
  );
};

export default Slider;
